export class TreatyCoverageCodes{    
  covTypeId: number;
  covTypeCode: string;    
}
export class addLayerRequest{
  efectiveDate: string;
  treatyCoverageCodes: TreatyCoverageCodes[];
}

export class TreatiesPfResponse {
  riskId: string;
  treaties: TreatyPfResponse[]; 
} 
export class TreatyPfResponse{
  coverageId?: string;
  triaId?: string;
  layer?: number;
  coverageCode: string;
  contractCode: string;
  limit: number;
  reinsuranceTreaty: string;
  tmhccRetention: number;
  cedingCommission: number;
  sedgwick: number;  
  premium?: number;
  tria?: number;
}
export class TreatyPfListResponse{
  treaties: TreatyPfResponse[];
}


  