import { Component, OnInit, AfterViewInit,Inject } from '@angular/core';
//import { OktaAuthService } from '@okta/okta-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from '@tmhcc-ng-lib/framwork/loader';
import { has } from '@tmhcc/util';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { ConfirmationDialogService } from '@tmhcc-ng-lib/framwork/custom-popup-alert';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
@Component({
  selector: 'ac-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Product Contamination Dashboard';
  isAuthenticated: boolean;
  loggedInUser: string;
  isLoading: boolean;
  showLogout: boolean;
  modelMsg =
    'Are you sure you want to leave this page? The changes you made will be lost.';

  constructor(
    //public oktaAuth: OktaAuthService,
    public router: Router,
    public loaderService: LoaderService,
    protected configService: AppConfigService,
    private confirmationDialogService: ConfirmationDialogService,    
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    // this.oktaAuth.$authenticationState.subscribe(
    //   (isAuthenticated: boolean) => (this.isAuthenticated = isAuthenticated)
    // );

    this.loaderService.isLoading.subscribe(loader => {
      setTimeout(() => {
        this.isLoading = loader;
      }, 300);
    });
    this.loaderService.show();
  }

  async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      await this.setWelcomeUser();
    }
  }

  async ngAfterViewInit() {
    // this.loaderService.hide();
  }

  async setWelcomeUser() {
    const userClaims = await this.oktaAuth.getUser();
    if (has(userClaims, 'email')) {
      sessionStorage.setItem('UserId', userClaims.email);
    }
    this.loggedInUser = userClaims.name;
  }

  async logout() {
    sessionStorage.removeItem('UserId');
    sessionStorage.removeItem('okta-app-url');
    sessionStorage.removeItem('navigator');
    sessionStorage.removeItem('claimInfo');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('okta-token-storage');
    // const url = `${this.configService.config.baseHref}/login`;
    // await this.oktaAuth.signOut({ postLogoutRedirectUri: url });
    await this.oktaAuth.signOut();
  }

  redirectToDashboard() {
    // if (this.router.url !== '/dashboard') {
    //   this.confirmationDiscardChanges();
    // } else {
    this.router.navigate(['dashboard/Quotes']);
    //window.location.reload();
    //window.location.href = '/dashboard/Quotes';
    //this.router.navigate(['dashboard'], { relativeTo: this.activeRouter.parent.parent });
    // console.log("route",this.activeRouter.parent.parent );
  }

  confirmationDiscardChanges() {
    this.confirmationDialogService
      .confirm(this.modelMsg)
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed) {
          this.router.navigate(['/dashboard']);
        }
      })
      .catch(() => console.log('User dismissed the confirmation box'));
  }

  public popupLogoutOnClick = false;
  closeLogoutPopup(event) {
    if (this.showLogout && !this.popupLogoutOnClick) {
      this.showLogout = false;
    } else {
      this.popupLogoutOnClick = false;
    }
  }
}
