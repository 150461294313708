import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { BaseDataService } from './base-data-service';
import {
  DashboardRequest,
  DashboardResponse,
  ApprovedRequestPost,
  ApprovedRequestPayment,
  ClaimDashBoardMetrics
} from './def/get-dashboard';
import { GetDashboardResponse } from './def/dashboard';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { has } from '@tmhcc/util';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { searchRequest } from '@tmhcc-ng-lib/framwork/grid';

@Injectable()
export class DashboardService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    protected dateService: DateService
  ) {
    super(http, configService);
  }

  // getDashboardData(subCompanyCode: string): Observable<DashboardResponse> {
  //   const payload: DashboardRequest = { SubCompanyCode: subCompanyCode };
  //   const url = `${this.serviceBaseUrl}/GetClaimInfoForDahsboard/${payload.SubCompanyCode}`;
  //   return this.get<DashboardResponse>(url);
  // }

  // DashboardApprove(params: ApprovedRequestPost): Observable<any> {
  //   const url = `${this.serviceBaseUrl}/BulkApproveOrRejectReserve`;
  //   return this.post<ApprovedRequestPost>(url, { body: params }, true);
  // }

  // DashboardPaymentApproveOrReject(
  //   params: ApprovedRequestPayment
  // ): Observable<any> {
  //   const url = `${this.serviceBaseUrl}/BulkApproveRejectPayment`;
  //   return this.post<ApprovedRequestPost>(url, { body: params }, true);
  // }

  // ClaimDashBoardMetrics(params: ClaimDashBoardMetrics): Observable<any> {
  //   const url = `${this.serviceBaseUrl}/ClaimDashboardMetrics`;
  //   return this.post<ClaimDashBoardMetrics>(url, { body: params }, false);
  // }

  getSearchData(payload: searchRequest): Observable<any> {
    //////debugger;
    payload.fields['SortOrder'] = payload.pagination.currentSort;
    payload.fields['SortColumn'] = payload.pagination.sortBy;
    payload.fields['PageNumber'] = payload.pagination.pageNumber;
    const url = `${this.serviceBaseUrl}/GetGridData`;
    return this.post<any>(url, { body: payload.fields }).pipe(
      map(data => {
        let returndata = this.mapperDashboardInfo(data, payload.fields['Type']);
        return {
          searchData: returndata.QuotesGridDetails,
          totalRows: returndata.TotalCount
        };
      }),
      catchError(this.handleError)
    );
  }
  
  /** Get All policies on dashbord
   * 
   * @param payload 
   * @returns 
   */
  getAllPolicies(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/policies`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  /** Search Policies based on search criteria
   * 
   * @param payload 
   * @returns 
   */
  searchPolicies(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/policies/searches`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  /** Get all Quotes for Grid or Search Quotes based on search crietaria
   * 
   * @param payload 
   * @returns 
   */
  getAllOrSearchQuotes(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/submissions/searches`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  mapperDashboardInfo(data, Type) {
    let dashbordInfo: GetDashboardResponse = {
      QuotesGridDetails: [],
      TotalCount: 0
    };
    if (data) {
      if (has(data, 'QuotesGridDetails')) {
        data['QuotesGridDetails'].forEach(element => {
          dashbordInfo.QuotesGridDetails.push({
            TransactionType: element.TransactionType,
            InsuredName: element.InsuredName,
            TradeName: element.TradeName,
            EffectiveDate: element.EffectiveDate,
            ExpirationDate: element.ExpirationDate,
            GrossPremium: element.GrossPremium,
            AggregateLimit: element.AggregateLimit,
            BrokerName: element.BrokerName,
            Underwriter: element.Underwriter,
            PolicyNumber: Type === 'Policies' ? element.PolicyNumber : 0,
            QuoteNumber: Type === 'Quotes' ? element.QuoteNumber : 0
          });
        });
      }
      dashbordInfo.TotalCount = data.TotalCount;
    }
    return dashbordInfo;
  }

  // formatDate(element) {
  //   let date;
  //   if (has(element, 'CalendarDate')) {
  //     let calDate = this.dateService.toLongISO(
  //       this.dateService.moment(element.CalendarDate)
  //     );
  //     date = new Date(calDate);
  //   } else {
  //     date = '';
  //   }
  //   return date;
  // }

  private handleError(err) {
    return throwError(err);
  }
}
