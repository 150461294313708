import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ClaimContact, ClaimContactPayload } from '@tmhcc-ng-lib/models';
import { isEmpty } from 'lodash';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PciInsuredSearchService extends BaseDataService {
  private contactCached: ClaimContact[];
  private isContactAvailable: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  isclaimDataReady = this.isContactAvailable.asObservable();

  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  get masterClaimData() {
    if (!this.contactCached) {
      throw new Error(
        'Claim contact data must be fetched before it can be used.'
      );
    }
    return this.contactCached;
  }

  getInsuredContactSearch(payload: ClaimContactPayload): Observable<any> {
    const url = `${this.serviceBaseUrl}/api/v1/contacts/search`;

    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        this.contactCached = this.claimDataMapper(data);
        return this.contactCached;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    return throwError(err);
  }

  private claimDataMapper(data: any): ClaimContact[] {
    if (!data || !data.contacts) return [];

    let contactRows: ClaimContact[] = [];
    for (let index = 0; index < data.contacts.length; index++) {
      const element = data.contacts[index];

      let row = new ClaimContact();
      row.id = element.id;
      row.insuredName = element.insuredName;
      row.address = element.address;
      row.city = element.city;
      row.state = element.state;
      row.zipCode = element.zipCode;
      row.country = element.country;
      contactRows.push(row);
    }
    return contactRows;
  }
}
